import * as React from 'react';
import PropTypes from 'prop-types';

// components
import CloseIcon from '@mui/icons-material/Close';
// @mui
import { Stack, Dialog, IconButton, DialogTitle, DialogContent } from '@mui/material';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useResponsive } from 'src/hooks/use-responsive';

import OnBoardingStepper from 'src/components/stepper/stepper';

import WithdrawalRequestContent from './Withdrawal-requestsContent';

WithdrawalRequestsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenWithdrawalRequestSuccess: PropTypes.func,
  tabValue: PropTypes.any,
};

export default function WithdrawalRequestsDialog({
  open,
  setOpen,
  setOpenWithdrawalRequestSuccess,
  tabValue,
}) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  // const { role } = useGetRoles();
  // console.log(isUpdate);

  const handleClose = () => {
    setOpen(false);
  };
  // const router = useRouter();

  // const { enqueueSnackbar } = useSnackbar();

  const steps = [' Verification ', 'Amount', 'Wallet'];

  const [activeStep, setActiveStep] = React.useState(tabValue);
  // console.log(activeStep);
  // const [completed, setCompleted] = React.useState({});
  // const handleNext = () => {
  //   const newActiveStep =
  //     isLastStep() && !allStepsCompleted()
  //       ? steps.findIndex((step, i) => !(i in completed))
  //       : activeStep + 1;
  //   setActiveStep(newActiveStep);
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  // };
  // console.log(handleNext);
  // const totalSteps = () => steps.length;

  // const completedSteps = () => Object.keys(completed).length;

  // const isLastStep = () => activeStep === totalSteps() - 1;

  // const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // React.useEffect(() => setActiveStep(0), []);
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title">Withdrawal requests</DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '200px' : '580px', padding: '0px' }}>
          <OnBoardingStepper activeStep={activeStep} steps={steps} h />

          <Stack>
            <WithdrawalRequestContent
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleNextStep={handleNextStep}
              handleClose={handleClose}
              handlePreviousStep={handlePreviousStep}
              setOpenWithdrawalRequestSuccess={setOpenWithdrawalRequestSuccess}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
