import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Card,
  Alert,
  MenuItem,
  Accordion,
  CardMedia,
  IconButton,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

import timer from 'src/assets/other/timer.png';
import empty from 'src/assets/other/empty.png';
import { deleteWalletAddress, getAllWalletAddress } from 'src/server/api/walletAddress';

import Iconify from 'src/components/iconify';
import CustomPopover from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';

import WalletAddressDialog from 'src/sections/walletAddress/walletAdressModal';

import TimerComponent from './timerComponent';

// ----------------------------------------------------------------------

export default function WalletAddressDetailsMember() {
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');

  const [openWalletAddressId, setOpenWalletAddressId] = useState({});
  const [openWalletAddressDialog, setOpenWalletAddressDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [dateCheck, setDateCheck] = useState(false);

  const { memberWalletAddresses, memberProfileDetails } = useSelector((state) => ({
    loading: state.referrals.loading,
    memberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    // DataList: state.walletAddress.walletAddresses.walletAddresses,
    memberWalletAddresses: state.walletAddress?.walletAddresses?.memberWalletAddresses,
  }));
  // console.log(DataList);
  const isDisabled = memberWalletAddresses && memberWalletAddresses?.length > 2;

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      // limit: storedRowsPerPage || table.rowsPerPage,
      limit: 5,
      dispatch,
    };
    dispatch(getAllWalletAddress(credentials));
  }, [dispatch]);

  const handleDelete = (item) => {
    setSelectedItem(item);
    confirm.onTrue();
  };
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  // console.log(selectedItem);
  const handleDeleteConfirmClick = (id) => {
    // console.log('deleteidi', id);

    const credentials = {
      dispatch,
      walletId: id,
    };
    // console.log(id);

    dispatch(deleteWalletAddress(credentials));
    confirm.onFalse();
  };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [popover, setPopover] = useState({ open: false, index: null, mouseX: 0, mouseY: 0 });

  // const handlePopoverOpen = (event, index) => {
  //   setPopover({ open: true, index });
  // };

  const handlePopoverClose = () => {
    setPopover({ open: false, index: null, mouseX: 0, mouseY: 0 });
  };
  // console.log('gggg------>>',formatRemainingTime(
  //   memberProfileDetails?.member?.wallet_address_last_updated_at
  // ))

  return (
    <>
      {openWalletAddressDialog && (
        <WalletAddressDialog
          memberId={memberProfileDetails?.member?._id}
          isUpdate={openWalletAddressId}
          setOpenWalletAddressId={setOpenWalletAddressId}
          setOpen={setOpenWalletAddressDialog}
          open={openWalletAddressDialog}
        />
      )}
      <Stack
        spacing={3}
        mb={2}
        direction={{ xs: 'row', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        justifyContent="space-between"
      >
        <Typography variant="h6">Wallet Address</Typography>
        <Tooltip title={isDisabled ? 'Only 3 wallet addresses are allowed' : ''}>
          <Button
            disabled={isDisabled}
            type="submit"
            size="small"
            startIcon={<Iconify icon="mingcute:add-line" sx={{ width: '15px' }} />}
            onClick={() => {
              setOpenWalletAddressDialog(true);
            }}
            sx={{ flexShrink: 0, color: 'black', backgroundColor: '#f0f0f0' }}
          >
            Add
          </Button>
        </Tooltip>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          py: 0,
          // minHeight: 300,
          height: '100%',
          // border: '1px solid #e3e7ea',
          boxShadow: 'unset',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {memberWalletAddresses && memberWalletAddresses?.length > 0 ? (
          <Stack className="wallet-address" spacing={0} mb={2}>
            {(memberWalletAddresses || []).map((item, index) => (
              <>
                <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary
                    sx={{
                      borderRadius: '8px',
                      px: 2,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box display="flex" alignItems="center" flexGrow={1}>
                      <IconButton
                        size="small"
                        onClick={() => setExpanded(expanded === index ? false : index)}
                        sx={{ mr: 1 }}
                      >
                        <ArrowRightIcon
                          sx={{
                            transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s',
                            color: 'black',
                          }}
                        />
                      </IconButton>
                      <Typography variant="body2" sx={{ flexGrow: 1 }}>
                        {item?.title ? item?.title : 'No Title'}
                      </Typography>
                    </Box>
                    <CopyToClipboard text={item?.wallet_id} onCopy={handleCopy}>
                      <Tooltip title="Copy Wallet">
                        <IconButton
                          size="small"
                          sx={{ justifyContent: 'center', borderRadius: '8px' }}
                        >
                          <Typography variant="caption" color="#00A76F">
                            Copy
                          </Typography>
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>

                    {/* <IconButton
                      size="small"
                      onClick={(e) => handlePopoverOpen(e, index)}
                      sx={{ mr: 1, position: 'relative' }}
                    >
                      <MoreVertIcon
                        sx={{
                          transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.2s',
                        }}
                      />
                    </IconButton> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        px: 5,
                        maxWidth: isMobile ? '300px' : '550px',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="#637381"
                        gutterBottom
                        style={{
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {item?.wallet_id ? item?.wallet_id : 'No Wallet ID'}
                      </Typography>
                      {item?.withdraw_message && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="body2" sx={{ color: '#637381' }}>
                            {item?.withdraw_message}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <CustomPopover
                  open={popover.open && popover.index === index}
                  onClose={handlePopoverClose}
                  arrow="right-top"
                  anchorReference="anchorPosition"
                  anchorPosition={
                    popover.open ? { top: popover.mouseY, left: popover.mouseX } : undefined
                  }
                  sx={{ width: 140 }}
                >
                  <Tooltip title="Remove Wallet">
                    <MenuItem
                      onClick={() => {
                        handleDelete(item);
                        handlePopoverClose();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                      Delete
                    </MenuItem>
                  </Tooltip>
                  <Tooltip title="Edit Wallet">
                    <MenuItem
                      onClick={() => {
                        handlePopoverClose();
                        setOpenWalletAddressId(item);
                        setOpenWalletAddressDialog(true);
                      }}
                    >
                      <Iconify icon="solar:pen-bold" />
                      Edit
                    </MenuItem>
                  </Tooltip>
                </CustomPopover>
              </>
            ))}
          </Stack>
        ) : (
          <Stack p={4} justifyContent="center" alignItems="center" gap={1} py={7}>
            <CardMedia component="img" src={empty} alt="copy" sx={{ width: '120px' }} />
            <Typography variant="caption" fontWeight="bold">
              No wallet address yet!
            </Typography>
            <Typography variant="caption" color="#637381">
              Click ‘+Add’ to set up your wallet address and begin making transactions!
            </Typography>
          </Stack>
        )}

        {memberWalletAddresses && memberWalletAddresses?.length > 0 && (
          <Stack
            mt={1.5}
            mx={4}
            mb={2}
            flexDirection={isMobile ? 'column' : 'column'}
            justifyContent="space-between"
            // sx={{ position: 'absolute', bottom: 16, right: 16 }}
          >
            {memberWalletAddresses && memberWalletAddresses?.length > 0 && !dateCheck && (
              <Alert
                className="alert-custom"
                icon={<Iconify icon="material-symbols:info" sx={{ color: '#B76E00' }} />}
                sx={{
                  bgcolor: '#F8F6F2',
                  color: '#00A76F',
                  borderRadius: '10px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  p: 0.5,
                  px: 1,
                  mb: 1,
                  width: '100%',
                }}
              >
                <Typography
                  variant="caption"
                  color="#B76E00"
                  sx={{ fontWeight: 500, display: 'flex', gap: 0.5, alignItems: 'center' }}
                >
                  You can withdraw your funds from your Winbuks wallet in{' '}
                  <TimerComponent
                    setDateCheck={setDateCheck}
                    updatedAt={moment(
                      memberProfileDetails?.member?.wallet_address_last_updated_at
                    ).toISOString()}
                  />
                </Typography>
              </Alert>
            )}
            {memberProfileDetails?.member?.wallet_address_last_updated_at && (
              <Stack alignItems="center" flexDirection="row" justifyContent="center" flex={7}>
                <CardMedia
                  component="img"
                  src={timer}
                  alt="copy"
                  sx={{ width: '18px', marginRight: '5px' }}
                />

                {isMobile ? (
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    Last updated{' '}
                    {fDateTime(memberProfileDetails?.member?.wallet_address_last_updated_at)}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    Last updated{' '}
                    {fDateTime(memberProfileDetails?.member?.wallet_address_last_updated_at)}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </Card>

      {/* <Card
        sx={{
          py: 3,
          minHeight: 300,
        }}
      >
        <Stack
          px={3}
          spacing={3}
          direction={{ xs: 'row', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
          justifyContent="space-between"
        >
          <Typography variant="h6">Wallet Address</Typography>
          <Button
            type="submit"
            size="small"
            startIcon={<Iconify icon="mingcute:add-line" />}
            // onClick={setOpenWalletAddressDialog(true)}
            onClick={() => {
              setOpenWalletAddressDialog(true);
            }}
            sx={{ flexShrink: 0, color: '#00A76F' }}
          >
            Add Wallet
          </Button>
        </Stack>
        <Box
          sx={{
            pt: 2,
            px: 2,
            mx: 1,
       
          }}
        >
          {memberWalletAddresses && memberWalletAddresses?.length > 0 ? (
            <Stack spacing={1} mb={2}>
              {(memberWalletAddresses || []).map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    border: '1px solid #00B8D9 ',
                    borderRadius: '10px',
                    // boxShadow: 'box-shadow: 4px 4px 4px 0px #00000040',
                    boxShadow:
                      '6px 6px 2px 0 rgb(91 94 98 / 20%), 14px 16px 24px -4px rgb(120 127 133 / 12%)',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                    overflow={isMobile && 'scroll'}
                    width={isMobile ? 300 : 'auto'}
                  >
                    <Typography>
                      {item?.title ? `${item?.title} : ` : ''}
                      {item?.wallet_id}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={isMobile ? 0 : 1}
                      padding={isMobile && 1.5}
                      px={isMobile && 1}
                      sx={{
                        position: isMobile && 'absolute',
                        right: isMobile && 0,
                        bgcolor: isMobile && '#f3f2f2',
                      }}
                    >
                      <CopyToClipboard text={item?.wallet_id} onCopy={handleCopy}>
                        <Tooltip title="Copy Wallet">
                          <Button size="small" color="info" sx={{ minWidth: '27px' }}>
                            <CardMedia
                              component="img"
                              src={copy}
                              alt="copy"
                              sx={{ width: '20px' }}
                            />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Edit Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            setOpenWalletAddressId(item);
                            setOpenWalletAddressDialog(true);
                          }}
                        >
                          <Iconify icon="heroicons-outline:pencil-alt" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Remove Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <Iconify icon="heroicons-outline:trash" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Card>
              ))}
              <Stack
                flexDirection={isMobile ? 'column' : 'row'}
                width={isMobile ? '85%' : '90%'}
                justifyContent="space-between"
                mx={!isMobile && 2}
                // sx={{ position: 'absolute', bottom: 16, right: 16 }}
              >
                <Stack alignItems="left" flexDirection="row" justifyContent="center" flex={7}>
                  <ErrorOutlineIcon
                    sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    You can only withdraw funds from your Winbuks wallet 24 hours after updating
                    your wallet address.{' '}
                  </Typography>
                </Stack>
                {memberProfileDetails?.member?.wallet_address_last_updated_at && (
                  <Stack
                    flex={5}
                    mt={isMobile && 1.5}
                    flexDirection=""
                    alignItems="end"
                    justifyContent="start"
                    // sx={{ position: 'absolute', cursor: 'pointer', bottom: 16, right: 30 }}
                  >
                    <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>
                      Last changed
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      {fDateTime(memberProfileDetails?.member?.wallet_address_last_updated_at)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack
              flexDirection="column"
              spacing={2}
              alignItems="center"
              // mb={MemberProfileDetails?.member?.wallet_address_last_updated_at && 5}
            >
              <Stack alignItems="center">
                <CardMedia component="img" src={wallet} alt="dashboard" sx={{ width: '250px' }} />
              </Stack>
              <Stack
                flexDirection="row"
                width="100%"
                justifyContent={
                  memberProfileDetails?.member?.wallet_address_last_updated_at
                    ? 'space-between'
                    : 'center'
                }
              >
                <Stack alignItems="left" flexDirection="row" justifyContent="center">
                  <ErrorOutlineIcon
                    sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="center"
                    alignItems="center"
                    mt={0.2}
                  >
                    You can only withdraw funds from your Winbuks <br />
                    wallet 24 hours after updating your wallet address.{' '}
                  </Typography>
                </Stack>
                {memberProfileDetails?.member?.wallet_address_last_updated_at && (
                  <Stack
                    flexDirection=""
                    alignItems="start"
                    justifyContent="start"
                    // sx={{ position: 'absolute', cursor: 'pointer', bottom: 16, right: 30 }}
                  >
                    <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>
                      Last changed
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      {fDateTime(memberProfileDetails?.member?.wallet_address_last_updated_at)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </Card> */}

      <ConfirmDialog
        title={`Are you sure want to delete  '${selectedItem ? selectedItem?.address : ''}' ? `}
        content="Are you sure you want to delete this Wallet Address? This action cannot be undone."
        action={
          <Button
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#106E58',
              '&:hover': {
                backgroundColor: '#0b4e3e',
                boxShadow: 'unset',
              },
            }}
            onClick={() => handleDeleteConfirmClick(selectedItem?._id)}
          >
            Delete
          </Button>
        }
        open={confirm.value}
        onClose={confirm.onFalse}
      />
    </>
  );
}
