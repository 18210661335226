import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
// @mui
import { Box, Stack, Dialog, Button, Typography, DialogContent } from '@mui/material';

import WithdrawalRequest from 'src/sections/withdrawalRequest/Withdrawal-requests';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useDispatch } from 'react-redux';

import { useResponsive } from 'src/hooks/use-responsive';

import { ResentOtpApi } from 'src/server/api/auth';

import WithdrawalRequestSuccess from 'src/sections/withdrawalRequest/Withdrawal-success-modal';

WithdrawalRequestsConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  memberProfileDetails: PropTypes.object,
  createDialog: PropTypes.bool,
  setCreateDialog: PropTypes.func,
};

export default function WithdrawalRequestsConfirmDialog({
  open,
  setOpen,
  memberProfileDetails,
  createDialog,
  setCreateDialog,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');

  const dispatch = useDispatch();
  const [openWithdrawalRequestSuccess, setOpenWithdrawalRequestSuccess] = useState(false);
  const [openWithdrawalRequest, setOpenWithdrawalRequest] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    // Assuming loginData is accessible here
    setOpen(false);
    setOpenWithdrawalRequest(true);
    if (memberProfileDetails?.user?.email) {
      const otpResetCrud = {
        state: {
          email: memberProfileDetails?.user?.email,
        },
        type: 'withdrawal_request',
      };
      dispatch(ResentOtpApi(otpResetCrud));
    }
  };

  return (
    <div>
      {setOpenWithdrawalRequest && (
        <WithdrawalRequest
          setOpen={setOpenWithdrawalRequest}
          open={openWithdrawalRequest}
          setOpenWithdrawalRequestSuccess={setOpenWithdrawalRequestSuccess}
          tabValue={0}
        />
      )}

      {openWithdrawalRequestSuccess && (
        <WithdrawalRequestSuccess
          setOpen={setOpenWithdrawalRequestSuccess}
          open={openWithdrawalRequestSuccess}
        />
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box
          textAlign={isMobile && 'center'}
          sx={{ marginTop: isMobile && '50vh', transform: isMobile && 'translateY(-50%)' }}
        >
          <DialogContent
            sx={{
              minWidth: isMobile ? 'auto' : '580px',
              padding: isMobile ? '' : '50px',
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="start"
              color="warning"
              padding={2}
            >
              <InfoRoundedIcon sx={{ fontSize: 16 }} color="warning" /> &nbsp;{' '}
              <Typography variant="subtitle2">Are you confirm to withdrawal your wallet</Typography>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent={isMobile ? 'center' : 'end'}
            >
              <Button variant="outlined" color="inherit" size="small" onClick={() => handleClose()}>
                cancel
              </Button>
              <Button
                sx={{ mx: 1 }}
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleReset()}
              >
                confirm
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
