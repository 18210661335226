import * as React from 'react';
import PropTypes from 'prop-types';

// components
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import {
  Grid,
  Card,
  Stack,
  Dialog,
  Button,
  Select,
  Tooltip,
  MenuItem,
  CardMedia,
  Typography,
  IconButton,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
} from '@mui/material';

// @mui
import copyWhite from 'src/assets/other/copygreen2.png'; // import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingButton } from '@mui/lab';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

import { useResponsive } from 'src/hooks/use-responsive';

import { updateWithdrawalRequestAdmin } from 'src/server/api/withdrawalRequest';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

WithdrawalRequestApprove.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function WithdrawalRequestApprove({ open, setOpen, isUpdate }) {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = React.useState();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'md');
  const balance = (isUpdate?.amount || 0) - (isUpdate?.deduction_amount || 0);

  const handleClose = () => {
    setOpen(false);
  };

  const defaultValues = React.useMemo(
    () => ({
      remarks: isUpdate?.remarks || '',
    }),
    [isUpdate]
  );

  const methods = useForm({
    // resolver: yupResolver(memberCreateSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    // formState: { isSubmitting },
    // reset,
  } = methods;
  // console.log(isSubmitting);

  // const handleStatusChange = (value) => {
  //   setStatusValue(value);
  // };

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    const credentialsUpdate = {
      state: {
        ...data,
        id: isUpdate?._id,
        status: statusValue,
      },
      dispatch,
      handleClose,
      // handleClear,
    };
    // if () {
    //   const credentialsUpdate = {
    //     state: {
    //       ...data,
    //       is_active: isActive,
    //       is_verified: isVerified,
    //       is_paid: isPaid,
    //     },
    //     dispatch,

    //     navigate,
    //   };
    // dispatch(updateMemberDetails(credentialsUpdate));
    dispatch(updateWithdrawalRequestAdmin(credentialsUpdate));
  });
  // const router = useRouter();
  // console.log(isUpdate);
  const handleCopy = () => {
    enqueueSnackbar('Wallet Address copied!', { variant: 'success' });
  };
  const handleTypeChange = (event) => {
    const updatedStatus = event.target.value;
    setStatusValue(updatedStatus);
  };
  const statusOptions = [
    { label: 'Processing', value: 'PROCESSING' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ];
  React.useEffect(() => {
    setStatusValue(isUpdate?.status);
  }, [isUpdate?.status]);
  // const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title" sx={{ mt: isMobile && 2 }}>
          Update withdraw request
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '250px' : '540px', padding: '10px' }}>
          {/* <Stack px={3} flexDirection="row" justifyContent="start">
            <Box>
            // ....
              {isUpdate?.member?.name} &nbsp;({isUpdate?.request_id})
            </Box>{' '}
            <Box ml={5}>{isUpdate?.member?.member_id} </Box>
          </Stack> */}
          <Stack flexDirection={isMobile ? 'column' : 'row'}>
            <Stack ml={2} mb={3} flex={6}>
              <Stack>
                <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                  Member Details :
                </Typography>
              </Stack>
              <Stack flexDirection="column" ml={2}>
                <Grid display="flex" alignItems="center">
                  <PersonIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
                  <Typography variant="body2">
                    {' '}
                    {isUpdate?.member?.name} ({isUpdate?.member?.member_id})
                  </Typography>
                </Grid>
                <Grid display="flex" alignItems="center">
                  <LocalPhoneRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
                  <Typography variant="body2">
                    {' '}
                    {isUpdate?.member?.country_code}&nbsp;{isUpdate?.member?.phone}
                  </Typography>
                </Grid>
                <Grid display="flex" alignItems="center">
                  <MarkEmailUnreadRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
                  <Typography variant="body2">{isUpdate?.member?.email}</Typography>
                </Grid>
                {/* <Grid display="flex" alignItems="center">
                  <Typography variant="body2">Wallet Balance:</Typography> &nbsp;
                  <Typography variant="body2">
                    {isUpdate?.member?.wallet_balance?.toFixed(2)}
                  </Typography>
                </Grid> */}
                {/* <Stack>
             <Typography variant="caption">{isUpdate?.member?.name} </Typography>
              </Stack> */}
              </Stack>
              <Stack mt={2}>
                <Grid display="flex" alignItems="center">
                  <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                    Wallet Balance:
                  </Typography>{' '}
                  &nbsp;
                  <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
                    {isUpdate?.member?.wallet_balance?.toFixed(2)}
                  </Typography>
                </Grid>
                {/* <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>Wallet Balance:</Typography> */}
              </Stack>
            </Stack>
            <Stack ml={2} mb={3} flex={6} mt={!isMobile && 2.5}>
              <Grid display="flex" alignItems="center" justifyContent="start" mr={2}>
                <Typography variant="body2" sx={{ fontWeight: '700' }}>
                  Withdrawal amount :
                </Typography>{' '}
                &nbsp;
                <Typography variant="body2"> {isUpdate?.amount} USDT</Typography>
              </Grid>
              <Grid display="flex" alignItems="center" justifyContent="start" mr={2}>
                <Typography variant="body2" sx={{ fontWeight: '700' }}>
                  Deduction amount :
                </Typography>{' '}
                &nbsp;
                <Typography variant="body2"> {isUpdate?.deduction_amount} USDT</Typography>
              </Grid>
              <Grid display="flex" alignItems="center" justifyContent="start" mr={2}>
                <Typography variant="body2" sx={{ fontWeight: '700' }}>
                  Balance :
                </Typography>{' '}
                &nbsp;
                <Typography variant="body2"> {balance} USDT</Typography>
              </Grid>
            </Stack>
          </Stack>
          <Stack>
            <Stack mx={2}>
              <Typography variant="subtitle2" sx={{ fontWeight: '700', mb: 1 }}>
                Wallet Address :
              </Typography>
            </Stack>
            <Card
              sx={{
                mb: 3,
                p: 1,
                mx: 2,
                borderRadius: '10px',
                boxShadow: 'unset',
                border: '1px solid #e9ecee',
                textAlign: 'start',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  textAlign: 'start',
                }}
                variant="body2"
              >
                {isUpdate?.wallet_address}
              </Typography>
              <CopyToClipboard
                // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                text={isUpdate?.wallet_address}
                onCopy={handleCopy}
              >
                <Tooltip title="Copy Wallet ID">
                  <Button
                    size="small"
                    variant="outline"
                    startIcon={
                      <CardMedia
                        component="img"
                        src={copyWhite}
                        alt="copy"
                        sx={{ width: '25px', height: '25px', marginRight: '0px' }}
                      />
                    }
                    sx={
                      {
                        // marginLeft: '8px',
                        // width: '40px',
                        // backgroundColor: '#00A76F',
                        // height: '38px',
                        // '&:hover': { backgroundColor: '#047f56' },
                      }
                    }
                  >
                    Copy
                  </Button>
                </Tooltip>

                {/* Copy ID */}
                {/* </Button> */}
              </CopyToClipboard>
            </Card>
            <Stack>
              {/* <CopyToClipboard
              text={settingsDetails?.account_credentials?.wallet_id}
              onCopy={handleCopy}
            >
              <Button
                size="small"
                variant="contained"
                startIcon={
                  <CardMedia component="img" src={copyWhite} alt="copy" sx={{ width: '15px' }} />
                }
                sx={{
                  width: '120px',
                  backgroundColor: '#00A76F',
                  '&:hover': { backgroundColor: '#047f56' },
                }}
              />
              
            </CopyToClipboard> */}
            </Stack>
          </Stack>
          {/* <Stack px={3} flexDirection="row" justifyContent="start" pt={1} pb={2}>
            <Box>
              {isUpdate?.member?.email && isUpdate.member.email},&nbsp;
              {isUpdate.member.phone && isUpdate.member.phone}
            </Box>
            &nbsp;
            <Box ml={5} color="#118D57">
              ${isUpdate?.amount}
            </Box>
          </Stack> */}

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack px={2}>
              <RHFTextField
                name="remarks"
                label="Remarks"
                type="string"
                placeholder="Enter Remarks"
                InputLabelProps={{ shrink: true }}
                rows={3}
                multiline
              />
            </Stack>
            <Stack className="all-list-select-ui" width={isMobile ? '100%' : '35%'} px={2} mt={3}>
              <FormControl width="" name="status" size="small">
                <InputLabel sx={{ backgroundColor: 'white' }}> Status</InputLabel>

                <Select
                  value={statusValue}
                  onChange={handleTypeChange}
                  //  sx={{ height: '29px' }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ color: 'black' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="end" spacing={1} py={2} px={2}>
              {/* <Button variant="outlined" onClick={handleClose}>
                Close
              </Button> */}

              {/* {isUpdate?.status !== 'IN_PROGRESS' && (
                <LoadingButton
                  type="submit"
                  color="info"
                  variant="outlined"
                  onClick={() => handleStatusChange('IN_PROGRESS')}
                  sx={{ px: 1 }}
                >
                  Progress
                </LoadingButton>
              )} */}
              {/* <LoadingButton
                type="submit"
                color="error"
                variant="contained"
                onClick={() => handleStatusChange('CANCELLED')}
                sx={{ px: 1 }}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() => handleStatusChange('APPROVED')}
                color="success"
                sx={{ px: 2 }}
              >
                Confirm
              </LoadingButton> */}
              <LoadingButton
                type="submit"
                variant="contained"
                // onClick={() => handleStatusChange('APPROVED')}
                color="success"
                sx={{ px: 2 }}
              >
                Update
              </LoadingButton>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
