/* eslint-disable import/no-unresolved */
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
// import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Card, Chip, Link, Tooltip, CardMedia } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import win from 'src/assets/other/win.png';
import { logoutApi } from 'src/server/api/auth';
import { getAllMemberAccountMember } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import TextMaxLine from 'src/components/text-max-line';
import { useSettingsContext } from 'src/components/settings';
// import TextTruncate from 'src/components/Typography/TextTruncate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import CreateMemberAccountModalDialog from 'src/sections/Member/MemberSingleView/createMemberAccount';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({ changeTheme }) {
  // const router = useRouter();
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const settings = useSettingsContext();
  const dispatch = useDispatch();
  const router = useRouter();

  const isMobile = useResponsive('down', 'md');

  const { account, memberAccounts } = useSelector((state) => ({
    account: state.member.memberProfileDetails?.member,
    memberAccounts: state.member.memberAccounts,

    // partnerProfile: state.partner.profile,

    user: state.auth.user,
  }));
  const [openModalDialog, setOpenModalDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenModalDialog(true);
  };
  // console.log(memberAccounts);
  const { role } = useGetRoles();
  const popover = usePopover();
  // console.log(account?.uid)
  const accountId = account?._id;

  // console.log('accountId', accountId);

  // const OPTIONS = [
  //   {
  //     label: 'Home',
  //     linkTo: role === 'student' ? paths.public.academy_landing : paths.dashboard.root,
  //   },
  //   // {
  //   //   label: 'Profile',
  //   //   linkTo:
  //   //     role === 'student' ? paths.auth.student_profile : `${paths.dashboard.profile}#general`,
  //   // },
  //   // ...(user && user.user_roles && user.user_roles.length > 1
  //   //   ? [
  //   //       {
  //   //         label: 'Change Role',
  //   //         linkTo: `${paths.dashboard.profile}#security`,
  //   //       },
  //   //     ]
  //   //   : []),
  //   // {
  //   //   label: 'Change Role',
  //   //   linkTo: `${paths.dashboard.profile}#security`,
  //   // },
  //   // {
  //   //   label: 'Settings',
  //   //   linkTo: `${paths.dashboard.profile}#security`,
  //   // },
  // ];

  const signOff = async () => {
    popover.onClose();
    // navigate(paths.auth.logout);
    const credentials = {
      dispatch,
      navigate,
      role,
    };
    dispatch(logoutApi(credentials));
    if (changeTheme) {
      changeTheme('light');
    }
    settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  };

  const logout = () => {
    popover.onClose();
    navigate(role === 'admin' ? paths.auth.login : paths.auth.login_member);
    sessionStorage.clear();
    if (changeTheme) {
      changeTheme('light');
    }

    settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  };
  useEffect(() => {
    if (role !== 'admin') {
      const credential = {
        dispatch,
      };
      dispatch(getAllMemberAccountMember(credential));
    }
  }, [dispatch, role]);

  const accounts = Array.isArray(memberAccounts) ? memberAccounts : [];
  // console.log(accounts);
  // const handleClickItem = (path) => {
  //   popover.onClose();
  //   router.push(path);
  // };
  // console.log(account);
  const getInitials = (name) => {
    const names = name?.split(' ');
    return names?.map((n) => n.charAt(0).toUpperCase()).join('');
  };

  const handleSelectAccount = (id) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useSetMemberId(id);
    // router.push(paths.dashboard.dashboard_loading);
    // window.location.reload();
    router.push(paths?.public?.dashboard_loading(id));
  };
  return (
    <>
      {openModalDialog && (
        <CreateMemberAccountModalDialog
          setOpen={setOpenModalDialog}
          open={openModalDialog}
          // isUpdate={MemberProfileDetails}
          // MemberProfileDetails={MemberProfileDetails}
          // setIsUpdate={setInvestorData}
        />
      )}
      {!mdUp && (
        <Stack direction="column" alignItems="center">
          <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            onClick={popover.onOpen}
            sx={{
              width: 40,
              height: 40,
              // background: (theme) => alpha(theme.palette.grey[500], 0.08),
              // ...(popover.open && {
              //   background: (theme) =>
              //     `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
              // }),
            }}
          >
            <Avatar
              src={
                // eslint-disable-next-line no-nested-ternary
                role === 'admin'
                  ? win
                  : account?.profile_photo
                    ? account?.profile_photo?.url
                    : 'default-profile_photo-url'
              }
              alt={account?.name}
              sx={{
                width: 36,
                color: 'white',
                height: 36,
                borderRadius: '5px',

                backgroundColor: account?.is_primary ? '#8431AD' : '#31AD4C',

                border: (theme) => `solid 2px ${theme.palette.background.default}`,
              }}
            >
              {/* {account?.name?.charAt(0).toUpperCase() ?? ''} */}
              <Typography variant="caption" color="white">
                {' '}
                {getInitials(account?.name)}
              </Typography>{' '}
            </Avatar>
          </IconButton>

          {account?.member_id && (
            <Stack>
              <Typography fontSize="10px" color="text.disabled">
                ID:
                <Typography fontSize="10px" color="black" ml={0.5} component="span">
                  {account?.member_id}
                </Typography>{' '}
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      {mdUp && (
        <>
          {/*         
        <Stack onClick={popover.onOpen} alignItems="center" px={2}>
          <Typography
            variant="subtitle2"
            noWrap
            // sx={{ textTransform: 'capitalize' }}
          >
            {role === 'admin' ? 'Winbuks' : account?.user?.username}
          </Typography>

          {role === 'admin' ? (
            <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
              {role}
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{ textTransform: 'capitalize' }}
            >
              {role}
            </Typography>
          )}
        
        </Stack> */}
          <Stack
            onClick={popover.onOpen}
            p={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1.5}
            sx={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}
          >
            <Stack>
              {role === 'admin' ? (
                <CardMedia component="img" src={win} alt="winbuks" sx={{ width: '120px' }} />
              ) : (
                <Avatar
                  src={
                    account?.profile_photo
                      ? account?.profile_photo?.url
                      : 'default-profile_photo-url'
                  }
                  alt={account?.name}
                  sx={{
                    width: 45,
                    height: 45,
                    borderRadius: '5px',
                    backgroundColor: account?.is_primary ? '#8431AD' : '#31AD4C',

                    // border: (theme) => `solid 2px ${theme.palette.background.default}`,
                  }}
                >
                  <Typography variant="body2" color="white">
                    {' '}
                    {getInitials(account?.name)}
                  </Typography>{' '}
                  {/* {account?.name?.charAt(0).toUpperCase() ?? ''} */}
                </Avatar>
              )}
            </Stack>
            <Stack minWidth={role === 'admin' ? '80px' : '100px'}>
              {role === 'admin' ? (
                <Typography variant="body2" color="black">
                  Winbuks
                </Typography>
              ) : (
                <>
                  <TextMaxLine variant="body2" color="black" fontWeight="700" line={1}>
                    {account?.name}
                  </TextMaxLine>
                  <TextMaxLine variant="caption" color="text.disabled" line={1}>
                    {account?.user?.username}
                  </TextMaxLine>
                </>
              )}
            </Stack>
            <Stack direction="row" gap={1}>
              <Stack gap={1}>
                <Chip
                  label={role === 'admin' ? 'Admin' : 'Member'}
                  size="small"
                  sx={{
                    backgroundColor: '#BFD9C3',
                    color: '#03310A',
                    width: '70px',
                    fontSize: '12px',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: settings?.themeMode === 'dark' ? '#BFD9C3' : '#BFD9C3',
                    },
                  }}
                />
                {account?.member_id && (
                  <Stack>
                    <Typography variant="caption" color="text.disabled">
                      ID:
                      <Typography variant="caption" color="black" ml={0.5} component="span">
                        {account?.member_id}
                      </Typography>{' '}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Iconify icon="oui:arrow-down" sx={{ width: '15px', color: 'black' }} />
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{
          width: (() => {
            if (isMobile) return 200;
            if (role === 'admin') return 230;
            return 300;
          })(),
          p: 0,
        }}
      >
        <Stack sx={{ position: 'relative' }}>
          {role !== 'admin' && (
            <Link
              href={`${paths.dashboard.profile}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  p: 2,
                  pb: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    color: settings?.themeMode === 'dark' ? 'white' : 'black',
                  }}
                >
                  {/* <TextTruncate
                text={role === 'admin' ? 'Winbuks' : account?.user?.username}
                width={97}
              />
              <Chip sx={{ ml: 1 }} label={role} variant="outlined" color="warning" size="small" /> */}
                  Profile
                </Typography>
                <Iconify
                  icon="ep:arrow-right"
                  sx={{ width: '15px', color: settings?.themeMode === 'dark' ? 'white' : 'black' }}
                />
                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.phone}
            </Typography> */}
              </Box>
            </Link>
          )}
          {account?.is_guest && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10 }}
              size="small"
              color="warning"
              label="Guest"
            />
          )}
          {/* {account?.is_verified && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10, fontSize: '12px' }}
              size="small"
              color="success"
              label="Verified"
              icon={<VerifiedIcon fontSize="small" />}
            />
          )} */}
        </Stack>
        {role !== 'admin' && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 0 }}>
              <Typography color="#879589" variant="subtitle1" fontSize="12px" ml={2} mb={1} mt={1}>
                My Accounts
              </Typography>
              {accounts && accounts?.length > 0 ? (
                <Stack
                  sx={{
                    maxHeight: 290,
                    p: 1,
                    pt: 0.5,
                    overflowY: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'grey',
                      borderRadius: '15px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#F5F5F5',
                      borderRadius: '15px',
                    },
                  }}
                >
                  {accounts.map((item) => (
                    <MenuItem
                      sx={{ p: 0 }}
                      key={item?._id}
                      onClick={() => handleSelectAccount(item?._id)}
                    >
                      <Card
                        className="account-card-popover"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',

                          gap: 1,
                          mb: 0.5,
                          boxShadow: 'unset',
                          p: 1,
                          backgroundColor: accountId === item?._id ? '#f0f5f1' : 'white',
                          width: '100%',
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: item?.is_primary ? '#8431AD' : '#31AD4C',
                            width: '30px',
                            height: '30px',
                            borderRadius: '5px',
                          }}
                          src={
                            item?.profile_photo
                              ? item?.profile_photo?.url
                              : 'default-profile_photo-url'
                          }
                        >
                          <TextMaxLine variant="body2" line={1}>
                            {getInitials(item?.name)}
                            {/* {item?.name?.charAt(0).toUpperCase() ?? ''} */}
                          </TextMaxLine>
                        </Avatar>
                        {/* <Avatar
                  src={
                    account?.profile_photo
                      ? account?.profile_photo?.url
                      : 'default-profile_photo-url'
                  }
                  alt={account?.name}
                  sx={{
                    width: 45,
                    height: 45,
                    borderRadius: '5px',
                    // border: (theme) => `solid 2px ${theme.palette.background.default}`,
                  }}
                >
                  {account?.name?.charAt(0).toUpperCase() ?? ''}
                </Avatar> */}
                        <Stack>
                          <Typography
                            variant="caption"
                            color={settings?.themeMode === 'dark' ? 'black' : 'black'}
                          >
                            {item?.name}
                          </Typography>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Typography
                              fontSize="10px"
                              sx={{
                                wordWrap: 'break-word',
                                color: settings?.themeMode === 'dark' ? 'black' : 'black',
                              }}
                            >
                              ID: {item?.member_id}
                            </Typography>
                            {item?.is_primary && (
                              <Box
                                sx={{
                                  p: 0.5,
                                  backgroundColor: '#E3E3E3',
                                  borderRadius: '2px',
                                  height: '12px',
                                }}
                              >
                                <Typography mt={-0.6} fontSize="10px" color="#666666">
                                  Primary
                                </Typography>
                              </Box>
                            )}
                          </Stack>
                        </Stack>
                        {accountId === item?._id && (
                          <Stack ml={0.5}>
                            <Iconify
                              icon="hugeicons:tick-02"
                              sx={{ width: '15px', color: 'black' }}
                            />
                          </Stack>
                        )}
                      </Card>{' '}
                    </MenuItem>
                  ))}
                </Stack>
              ) : (
                <Stack>
                  <Typography variant="caption" fontWeight="bold" textAlign="center">
                    No account added yet!
                  </Typography>
                </Stack>
              )}
              {account?.is_verified && (
                <Stack
                  gap={1}
                  px={1}
                  mb={1}
                  ml={2}
                  my={2}
                  fontWeight={400}
                  direction="row"
                  onClick={handleOpenDialog}
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                >
                  <AddIcon sx={{ fontSize: '20px' }} />
                  <Typography fontWeight={400} variant="body2">
                    Add New Member
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}

        <Stack>
          <MenuItem
            sx={{
              m: 1,
              fontWeight: 'fontWeightBold',
              color: settings?.themeMode === 'dark' ? 'white' : '#860909',
            }}
            onClick={logout}
          >
            Logout
          </MenuItem>
          <Tooltip title="Logout from all device">
            <MenuItem
              id="signOffId"
              onClick={signOff}
              sx={{ m: 1, color: settings?.themeMode === 'dark' ? 'white' : '#03310A' }}
            >
              Logout from all devices{' '}
            </MenuItem>
          </Tooltip>
        </Stack>
      </CustomPopover>
    </>
  );
}

AccountPopover.propTypes = {
  changeTheme: PropTypes.any,
};
