import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Paper,
  Tooltip,
  TableRow,
  TableCell,
  CardMedia,
  Typography,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { USDT } from 'src/hooks/use-USDT';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import home from 'src/assets/other/home.png';
import illustration from 'src/assets/other/ic-canceled.png';
import {
  memberWalletAddress,
  getAllWithdrawalRequest,
  getAllWithdrawalRequestAdmin,
} from 'src/server/api/withdrawalRequest';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import WithdrawalRequestSort from 'src/sections/withdrawalRequest/withdrawalRequestSort';
import WithdrawalRequestToolbar from 'src/sections/withdrawalRequest/withdrawalRequestToolbar';
import WithdrawalRequestTableRow from 'src/sections/withdrawalRequest/withdrawalRequestTableRow';
import WithdrawalRequestsConfirmDialog from 'src/sections/withdrawalRequest/Withdrawal-requests-confirm';

// ----------------------------------------------------------------------

export default function WithdrawalRequestList({ memberSingle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const role = useGetRoles()?.role;
  const router = useRouter();
  const table = useTable({
    defaultOrderBy: 'createdAt',
    defaultRowsPerPage: role === 'admin' ? 25 : 5,
  });
  const confirm = useBoolean();
  const isMobile = useResponsive('down', 'md');
  const [sort, setSort] = useState('');
  const [date, setDate] = useState('');

  const {
    loading,
    dataList,
    memberProfileDetails,
    settingsData,
    memberWalletAddress: memberWalletAddressDetails,
    memberWalletAddressList,
  } = useSelector((state) => ({
    loading: state.referrals.loading,
    dataList: state.withdrawalRequest.withdrawalRequests,
    memberProfileDetails: state.member.memberProfileDetails.member,
    settingsData: state.settings.settingsDetails,
    memberWalletAddress: state.walletAddress?.walletAddresses?.memberWalletAddresses,
    memberWalletAddressList: state.withdrawalRequest?.memberWalletAddressList,
  }));
  // console.log(settingsData);
  const identifier = 'withdrawal_request';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [searchText, setSearchText] = useState();

  const [tableData, setTableData] = useState([]);
  const totalPage = dataList?.total;

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'created', label: 'Date' },
          { id: 'member_id', label: 'Request ID' },
          // { id: 'name', label: 'Member' },
          ...(memberSingle ? [] : [{ id: 'name', label: 'Member' }]),
          { id: 'phone', label: 'Amount' },
          { id: 'deduction', label: 'Deduction (10%)' },

          { id: 'email', label: 'Status' },
          { id: 'email', label: 'Action' },
        ]
      : [
          { id: 'created', label: 'Date' },
          { id: 'member_id', label: 'Request ID' },
          { id: 'name', label: 'Amount' },
          { id: 'phone', label: 'Remarks' },
          { id: 'email', label: 'Status' },
        ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: memberSingle ? 5 : storedRowsPerPage || table.rowsPerPage,
      sort,
      date,
      search: searchText || '',
      dispatch,
      member: memberSingle,
    };
    if (role === 'admin') {
      dispatch(getAllWithdrawalRequestAdmin(credentials));
    } else {
      dispatch(getAllWithdrawalRequest(credentials));
    }
  }, [
    date,
    dispatch,
    searchText,
    sort,
    role,
    storedRowsPerPage,
    table.page,
    table.rowsPerPage,
    memberSingle,
  ]);
  // console.log(searchText);
  useEffect(() => {
    setTableData(dataList?.withdrawalRequests);
  }, [dataList]);

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      limit: 5,
      dispatch,
    };
    if (role !== 'admin') {
      dispatch(memberWalletAddress(credentials));
    }
  }, [dispatch, role]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const currentDateTime = moment();
  // const lastUpdatedDateTime = new Date(memberProfileDetails?.wallet_address_last_updated_at);
  // const timeDifference = currentDateTime - lastUpdatedDateTime;
  const lastUpdatedAt = moment(memberProfileDetails?.wallet_address_last_updated_at);
  const diffInHours = currentDateTime.diff(lastUpdatedAt, 'hours');
  // const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  const isLessThanTwentyFourHours = diffInHours >= 24;

  // console.log(currentDateTime, lastUpdatedAt, diffInHours, isLessThanTwentyFourHours)
  // console.log(memberProfileDetails?.is_withdrawal_suspended);

  // console.log('currentDateTime', currentDateTime);
  // console.log('lastUpdatedDateTime', lastUpdatedDateTime);
  // console.log('isLessThanTwentyFourHoursi', isLessThanTwentyFourHours);
  const getWidth = () => {
    if (memberSingle) {
      return '100%';
    }
    if (isMobile) {
      return '100%';
    }
    return '80%';
  };
  const setPage = () => {
    table.setPage(0);
  };
  return (
    <>
      <WithdrawalRequestsConfirmDialog
        open={confirmDialog}
        setOpen={() => setConfirmDialog()}
        memberProfileDetails={memberProfileDetails}
      />
      {!memberSingle && (
        <CustomBreadcrumbs
          // heading="Withdrawal"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },
            {
              name: 'withdrawal',
            },

            {
              name: 'List',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      )}
      <Stack
        spacing={3}
        // width={isMobile ? '100%' : '80%'}
        width={getWidth()}
      >
        {role === 'admin' ? (
          !memberSingle && (
            <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }} width="100%">
              <Stack flex={1} p={1} spacing={4} justifyContent="start" flexDirection="row" pl={5}>
                <Stack justifyContent="center">
                  <CardMedia component="img" src={illustration} alt="" sx={{ width: '120px' }} />
                </Stack>
                <Stack justifyContent="center">
                  <Typography variant="h4">
                    {/* {`${fShortenNumber(
                    settingsData?.company_wallets?.actual_wallet_balance?.toFixed(2) || (0).toFixed(2)
                  )} ${USDT}`} */}
                    {`${
                      settingsData?.company_wallets?.actual_wallet_balance?.toFixed(2) ||
                      (0).toFixed(2)
                    }${USDT}`}
                  </Typography>

                  <Typography variant="body2" color="#919EAB">
                    Actual Wallet Balance{' '}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                backgroundColor="#cdf8ea"
                p={3}
                flexDirection={isMobile ? 'column' : 'row'}
                gap={2}
                sx={{ border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}` }}
              >
                <Stack flex={2.2}>
                  <Typography variant="body2" color="#004B50">
                    You can view the requests made by members to withdraw funds. To approve a
                    request, simply click the `Approve` button next to the request
                  </Typography>

                  <Typography variant="body2" color="#004B50" mt={1}>
                    Conversely, if a request cannot be approved, you can reject it by clicking the
                    `Reject` button. When rejecting a request, it`s important to add remarks
                    explaining why it was rejected.
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          )
        ) : (
          <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <Stack flex={5} p={1} spacing={4} justifyContent="center" flexDirection="row">
              <Stack justifyContent="center">
                <CardMedia component="img" src={illustration} alt="" sx={{ width: '120px' }} />
              </Stack>
              <Stack justifyContent="center">
                <Typography variant="h4">
                  {/* {memberProfileDetails?.wallet_balance?.toFixed(2)} {USDT} */}
                  {/* {`${fShortenNumber(memberProfileDetails?.wallet_balance?.toFixed(2))} ${USDT}`} */}
                  {`${memberProfileDetails?.wallet_balance?.toFixed(2) || (0).toFixed(2)} ${USDT}`}
                </Typography>

                <Typography variant="body2" color="#919EAB">
                  Wallet Balance
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={7}
              backgroundColor="#cdf8ea"
              p={3}
              py={1.5}
              flexDirection={isMobile ? 'column' : 'row'}
              gap={2.7}
              sx={{ borderLeft: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
            >
              <Stack flex={2.2}>
                <Tooltip
                  title={
                    memberProfileDetails?.wallet_balance === 0 && 'Wallet empty. Request denied.'
                  }
                >
                  <Stack
                    flex={1}
                    justifyContent="end"
                    alignItems="center"
                    flexDirection={isMobile ? 'row' : 'column'}
                  >
                    <Button
                      onClick={() => {
                        setConfirmDialog(true);
                      }}
                      disabled={
                        !memberProfileDetails?.wallet_balance > 0 ||
                        !isLessThanTwentyFourHours ||
                        (Array.isArray(memberWalletAddressList) &&
                          memberWalletAddressList?.length === 0) ||
                        memberProfileDetails?.is_withdrawal_suspended
                      }
                      size="small"
                      variant="contained"
                      startIcon={
                        <CardMedia component="img" src={home} alt="copy" sx={{ width: '20px' }} />
                      }
                      sx={{
                        width: 'auto',
                        backgroundColor: '#00A76F',
                        height: '34px',
                        '&:hover': { backgroundColor: '#047f56' },
                      }}
                    >
                      + New Withdrawal Request{' '}
                    </Button>
                  </Stack>
                  {memberProfileDetails?.wallet_balance <= 0 && (
                    <Stack mt={1} flexDirection="row" justifyContent="center">
                      <ErrorOutlineIcon
                        sx={{
                          marginRight: '5px',
                          color: 'primary.main',
                          fontSize: '15px',
                          mt: 0.5,
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="primary.main"
                        justifyContent="center"
                        alignItems="center"
                        mt={0.2}
                      >
                        Withdrawal requests cannot be made with 0 wallet balance.
                      </Typography>
                    </Stack>
                  )}
                  {memberProfileDetails?.is_withdrawal_suspended && (
                    <Stack mt={1} flexDirection="row" justifyContent="flex-start">
                      <ErrorOutlineIcon
                        sx={{
                          marginRight: '5px',
                          color: 'error.main',
                          fontSize: '15px',
                          mt: 0.5,
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="error.main"
                        justifyContent="center"
                        alignItems="center"
                        mt={0.2}
                      >
                        Withdrawal has been suspended. Please contact the admin.{' '}
                      </Typography>
                    </Stack>
                  )}

                  {!isLessThanTwentyFourHours && (
                    <Stack mt={1} flexDirection="row" justifyContent="center">
                      <ErrorOutlineIcon
                        sx={{
                          marginRight: '5px',
                          color: 'primary.main',
                          fontSize: '15px',
                          mt: 0.5,
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="primary.main"
                        justifyContent="center"
                        alignItems="center"
                        mt={0.2}
                      >
                        Withdrawal requests can only be made 24 hours after the last wallet update.
                      </Typography>
                    </Stack>
                  )}
                  {memberWalletAddressDetails && memberWalletAddressDetails?.length === 0 && (
                    <Stack mt={1} flexDirection="row" justifyContent="center">
                      <ErrorOutlineIcon
                        sx={{
                          marginRight: '5px',
                          color: 'primary.main',
                          fontSize: '15px',
                          mt: 0.5,
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="primary.main"
                        justifyContent="center"
                        alignItems="center"
                        mt={0.2}
                      >
                        Withdrawal requests cannot be made without a wallet address.
                        <Typography
                          component={Link}
                          to={paths?.dashboard?.profile}
                          variant="caption"
                          textAlign="center"
                          ml={1}
                        >
                          Add Wallet Address
                        </Typography>
                      </Typography>
                    </Stack>
                  )}
                </Tooltip>

                <Stack flexDirection="row" justifyContent="start" mt={1}>
                  <ErrorOutlineIcon
                    sx={{ marginRight: '5px', color: '#697077', fontSize: '15px', mt: 0.5 }}
                  />
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="start"
                    alignItems="center"
                    mt={0.2}
                  >
                    The withdrawal request will be processed within the next 72 hours. You will be
                    notified via your registered email once it is processed.{' '}
                  </Typography>
                </Stack>
                <Stack alignItems="center" flexDirection="row" justifyContent="start" mt={1}>
                  <ErrorOutlineIcon
                    sx={{ marginRight: '5px', color: '#697077', fontSize: '15px' }}
                  />
                  <Typography
                    variant="caption"
                    color="#697077"
                    justifyContent="start"
                    alignItems="center"
                    mt={0.2}
                  >
                    Note: Minimum withdrawal amount is 10 USDT.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        )}
        <Card>
          {!memberSingle && (
            <Stack p={2}>
              <Typography variant="h6">
                Withdrawal requests ({dataList?.withdrawalRequestCounts?.allWithdrawalRequestCount})
              </Typography>
            </Stack>
          )}
          <WithdrawalRequestSort
            setPage={setPage}
            sort={sort}
            setSort={setSort}
            dataList={dataList}
          />

          <Stack mx={2} my={3}>
            <WithdrawalRequestToolbar
              setPage={setPage}
              onSearch={handleSearch}
              date={date}
              setDate={setDate}
            />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <WithdrawalRequestTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 25}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      memberSingle={memberSingle}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            customRowsPerPage={role === 'admin' && !memberSingle && 25}
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

WithdrawalRequestList.propTypes = {
  memberSingle: PropTypes.string,
};
